import React, { useEffect, useState } from 'react';
import { AutocompleteProps, HelperState, Language } from '../../types';
import { fetchLanguagesRequest } from '../../api/handbook';
import { ControlledTagAutocomplete } from './ControlledTagAutocomplete';

interface Props {
  margin?: any;
  rules?: any;
  disabled?: boolean;
  helper: HelperState;
}

export const SpokenLanguagesField: React.FC<Props> = ({ margin, rules = {}, disabled = false, helper }) => {
  const { access_token } = helper;
  const [languages, setLanguages] = useState<Language[]>([]);

  const spokenLanguagesSelectProps: AutocompleteProps = {
    name: 'languages',
    items: languages,
    label: 'Select your spoken languages',
    margin,
    rules: rules,
    multiple: true,
    disabled,
  };

  useEffect(() => {
    let mounted = true;
    fetchLanguagesRequest(access_token as string).then(result => {
      if (mounted) {
        setLanguages(result.data.items);
      }
    });
    return () => {
      mounted = false;
    };
  }, [access_token]);

  return <ControlledTagAutocomplete {...spokenLanguagesSelectProps} />;
};
