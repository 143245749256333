import { Redirect, Route, Switch } from 'react-router-dom';

import { Maintenance } from '@vyce/core/src/views/maintenance';

import { WorkerRoute } from './WorkerRoute';
import { AuthRoute } from './AuthRoute';
import { Jobs } from '../views/jobs/Jobs';
import { WorkerProfile } from '../views/profile/WorkerProfile';
import { WorkerOnboarding } from '../views/onboarding/WorkerOnboarding';
import React from 'react';
import { Dashboard } from '../views/dashboard/Dashboard';
import { CompanyPreviewWrapper } from '../views/profile/CompanyPreviewWrapper';
import { Preview } from '../views/profile/Preview';
import { WorkerEmailVerification } from '../views/profile/WorkerEmailVerification';
import { WorkerAuth } from '../views/auth/WorkerAuth';

const createRoutes = () => (
  <Switch>
    <Route exact path={'/maintenance'} component={Maintenance} />

    {/*Authentication*/}
    <Route exact path={'/auth/verify_email/:token'} component={WorkerEmailVerification} />

    <AuthRoute path="/auth" component={WorkerAuth} />

    {/*Account with worker employer*/}
    <WorkerRoute path={'/onboarding'} component={WorkerOnboarding} />
    <WorkerRoute path="/dashboard" component={Dashboard} />
    <WorkerRoute exact path={'/jobs/my-reviews'} component={() => <h1>My Reviews</h1>} />
    <WorkerRoute path={'/jobs/:category/:job/:companyId'} component={CompanyPreviewWrapper} />
    <WorkerRoute path={'/jobs/:category/:job'} component={Jobs} />
    <WorkerRoute path={'/jobs/:category'} component={Jobs} />
    <WorkerRoute path={'/jobs'} component={Jobs} />
    <WorkerRoute path={'/help'} component={() => <h1>Help & Contact</h1>} />
    <WorkerRoute path={'/profile/:tab'} component={WorkerProfile} />
    <WorkerRoute path={'/profile'} component={Preview} />

    <Route path="*" render={() => <Redirect to={{ pathname: '/auth/login' }} />} />
  </Switch>
);

export default createRoutes;
