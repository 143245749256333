import { EmailVerification } from '@vyce/core/src/components/EmailVerificaion';
import React from 'react';
import { useActions } from "../../hooks/useActions";

export const WorkerEmailVerification: React.FC = () => {
  const { me } = useActions();

  return (
    <EmailVerification me={me}/>
  )
}
