export enum ActionType {
  // user
  SET_USER_DATA = 'set_user_data',
  SET_INITIAL_USER_STATE = 'set_initial_user_state',

  // helper
  SET_REDIRECT_TO = 'set_redirect_to',
  CLEAR_REDIRECT_TO = 'clear_redirect_to',
  SET_LOADING = 'set_loading',
  SET_NAV_ITEMS = 'set_nav_items',
  CLEAR_NAV_ITEMS = 'clear_nav_items',
  SET_ACCESS_TOKEN = 'set_access_token',
  CLEAR_HELPERS = 'clear_helpers',
  SET_SHOW_TOUR = 'set_show_tour',
}
