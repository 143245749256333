import React, { useEffect } from 'react';
import { Register } from '@vyce/core/src/views/auth/components/Register';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { AUTH_PATHS } from './WorkerAuth';

const WELCOME_MESSAGE = 'Welcome to Vyce Connect';
const SUBTITLE = 'connect to jobs, people & companies';

export const WorkerRegister: React.FC = () => {
  const { clearHelpers } = useActions();
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const { userRegister, setLoading } = useActions();

  useEffect(() => {
    clearHelpers();
  }, []);

  return (
    <Register
      redirectUrl="/onboarding"
      verifyPhoneUrl={AUTH_PATHS.VERIFY_PHONE}
      loading={userDataLoading}
      setLoading={setLoading}
      userRegister={userRegister}
      subtitle={SUBTITLE}
      title={WELCOME_MESSAGE}
    />
  );
};
