import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useTypedSelector } from '../hooks/useTypedSelector';

export const WorkerRoute = ({ component, ...rest }: any) => {
  const { user } = useTypedSelector((state) => state);

  const allowed = user?.uuid && user.worker?.uuid;
  const pathname = '/auth/login';

  const routeComponent = (props: any) =>
    allowed ? React.createElement(component, props) : <Redirect to={{ pathname }} />;
  return <Route {...rest} render={routeComponent} />;
};
