import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      maxWidth: '100vw',
    },
    content: {
      flexGrow: 1,
      width: '100%',
      minHeight: '100vh',
      paddingTop: theme.spacing(8),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(7),
      },
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    authLayout: {
      padding: 0,
    },
    rightIcons: {
      marginRight: theme.spacing(0.5),
    },
    spacer: {
      flexGrow: 1,
    },
    profileAvatarContainer: {
      marginRight: '-10px',
    },
    breadcrumbs: {
      marginBottom: theme.spacing(1),
    },
    breadcrumb: {
      fontSize: theme.typography.body2.fontSize,
    },
    breadcrumbLink: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    mobileBackButton: {
      marginLeft: theme.spacing(1),
    },
    progress: {
      margin: `-${theme.spacing(3)}px`,
      [theme.breakpoints.down('sm')]: {
        margin: `-${theme.spacing(1)}px`,
      },
    },
    servicesPaper: {
      padding: theme.spacing(3),
    },
    serviceCard: {
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
    serviceIcon: {
      marginRight: theme.spacing(2),
    },
    serviceTitle: {
      marginBottom: theme.spacing(1),
    },
    bold: {
      fontWeight: 600,
    },
    avatar: {
      height: '40px',
      width: '40px',
      backgroundColor: theme.palette.primary.main,
      marginRight: theme.spacing(1),
    },
    name: {
      color: theme.palette.type === 'dark' ? '#EFF0F6' : '#4E4B66',
      fontSize: '15px',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  })
);
