import React, { useContext, useMemo } from 'react';
import { useTheme } from '@material-ui/core';

import { AppTour } from '@vyce/core/src/components/AppTour';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import useStyles from '../styles';

const getMobileSteps = <T,>(mobileGeneralStyle: T) => [
  {
    selector: '[data-tour="navigation-menu"]',
    content: 'This is navigation menu.',
    style: mobileGeneralStyle,
  },
  {
    selector: '[data-tour="theme"]',
    content: 'Switch from light or dark view - you decide!',
    style: mobileGeneralStyle,
  },
  {
    selector: '[data-tour="show-tour"]',
    style: mobileGeneralStyle,
    content: 'Just click this icon to see this tour again.',
  },
];

const getSteps = <T, K extends { bold: string }>(generalStyle: T, classes: K) => [
  {
    selector: '[data-tour="My Profile"]',
    content: (
      <AppTour.TourContentItem
        title="Profile..."
        text="View, edit and share your profile here."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Dashboard"]',
    content: (
      <AppTour.TourContentItem
        title="Dashboard..."
        text="This is your dashboard where you’ll get a snapshot of all your activity and
        notifications from Vyce and the Vyce community."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Jobs"]',
    content: (
      <AppTour.TourContentItem
        title="Jobs..."
        text="Check out who’s hiring near you and apply to relevant jobs."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="theme"]',
    content: <AppTour.TourContentItem text="Switch from light or dark view - you decide!" />,
    style: generalStyle,
  },
  {
    selector: '[data-tour="show-tour"]',
    style: generalStyle,
    content: <AppTour.TourContentItem text="Just click this icon to see this tour again." />,
  },
];

export const ConnectAppTour: React.FC = () => {
  const { showTour } = useTypedSelector((state) => state.helper);
  const { setShowTour } = useActions();
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);

  const generalStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.background.paper,
      borderRadius: '16px',
      padding: theme.spacing(5),
      maxWidth: '500px',
    }),
    [theme]
  );

  const mobileGeneralStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
    }),
    [theme]
  );

  const mobileSteps = useMemo(() => getMobileSteps(mobileGeneralStyle), [mobileGeneralStyle]);
  const steps = useMemo(() => getSteps(generalStyle, classes), [generalStyle, classes]);

  return (
    <AppTour
      dialogTitle="Congratulations!"
      dialogSubtitle="Your profile is all set up"
      message="Let’s show you around quickly..."
      showTour={showTour}
      setShowTour={setShowTour}
      steps={isMobile ? mobileSteps : steps}
    />
  );
};
