import React from 'react';

import { NationalityField } from '@vyce/core/src/components/controlled-inputs/NationalityField';
import { SpokenLanguagesField } from '@vyce/core/src/components/controlled-inputs/SpokenLanguagesField';
import { ControlledDateField } from '@vyce/core/src/components/controlled-inputs/ControlledDateField';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

import { useTypedSelector } from '../../../hooks';

const maxDate = new Date().setFullYear(new Date().getFullYear() - 16);

export const NationalityForm: React.FC = () => {
  const { helper } = useTypedSelector((state) => state);

  return (
    <div>
      <OnboardingStepTitle text="Tell us more about you." />

      <NationalityField margin="normal" />

      <SpokenLanguagesField
        margin="normal"
        rules={{ required: 'Chose at least one language' }}
        helper={helper}
      />

      <ControlledDateField
        name="date_of_birth"
        label="Date of Birth"
        disableFuture
        maxDate={maxDate}
      />
    </div>
  );
};
