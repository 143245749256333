import React, { useContext, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { Box, Fade } from '@material-ui/core';

import { Position } from '@vyce/core/src/types';
import { WorkExperienceCard } from '@vyce/core/src/components/WorkExperienceCard';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../../hooks';
import { PositionForm } from './PositionForm';

interface Props {
  scrollDown: () => void;
}

export const WorkerExperienceForm: React.FC<Props> = ({ scrollDown }) => {
  const { setValue, getValues } = useFormContext();
  const [positions, setPositions] = useState<Position[]>(getValues('positions'));
  const { helper } = useTypedSelector(state => state);
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    setValue('positions', positions);
  }, [positions, setValue]);

  const deleteWorkExperience = (index: number) => {
    setPositions(items => items.filter((item, i) => i !== index));
  };

  const onPositionCreate = (position: Position) => {
    setPositions((items: Position[]) => [...items, position]);
    showNotification({
      message: 'Work experience has been created',
      options: { variant: 'success' },
    });
    scrollDown();
  };

  return (
    <>
      <OnboardingStepTitle text="Tell us about your previous work experience." />

      <Box marginTop={2}>
        <>
          {positions.map((position, index) => (
            <div key={index}>
              <Fade in={true}>
                <WorkExperienceCard
                  position={position}
                  positionIndex={index}
                  deletePosition={deleteWorkExperience}
                  helper={helper}
                />
              </Fade>
            </div>
          ))}
        </>
      </Box>

      <PositionForm save={onPositionCreate} />
    </>
  );
};
