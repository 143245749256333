import React, { useContext, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

interface Props {
  open: boolean;
  setOpenApplyJobDialog: Function;
  applyJob: (message: string) => void;
}

export const ApplyJobDialog: React.FC<Props> = ({ open, setOpenApplyJobDialog, applyJob }) => {
  const { isMobile } = useContext(DeviceContext);
  const [message, setMessage] = useState<string>('');

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={() => setOpenApplyJobDialog(false)}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        You can add an optional message to your application if you’d like...
      </DialogTitle>
      <DialogContent>
        <AppTextField
          id="bio"
          multiline
          fullWidth
          value={message}
          onChange={e => setMessage(e.target.value)}
          label="Message"
          placeholder="Write your message here..."
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => setOpenApplyJobDialog(false)}>
          Cancel
        </Button>
        <Button size="large" onClick={() => applyJob(message)} color="primary" variant="contained">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
