import { Box, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { JobsField } from '@vyce/core/src/components/controlled-inputs/JobsField';
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { DeviceContext } from "@vyce/core/src/contexts/deviceContext";
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

export const JobsForm: React.FC = () => {
  const { access_token } = useTypedSelector((state) => state.helper);
  const { isMobile } = useContext(DeviceContext);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={isMobile ? 'flex-start' : 'center'}
        flexDirection={isMobile ? 'column' : 'row'}>
        <OnboardingStepTitle text="Which jobs best describe what you do?"/>
        <Typography variant="caption" color="textSecondary">
          Select up to 3
        </Typography>
      </Box>

      <JobsField margin="normal" token={access_token}/>
    </div>
  );
};
