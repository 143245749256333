import React from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { AppRadioButton } from '../../../components/inputs';
import { OnboardingStepTitle } from '../../../components';

interface Props {
  name?: string;
}

export const TravelTimeForm: React.FC<Props> = ({ name = 'travel_time' }) => {
  const { control } = useFormContext();

  return (
    <div>
      <OnboardingStepTitle text="How long are you willing to travel?" />

      <Box marginTop={1}>
        <Controller
          render={({ field }) => (
            <FormControl>
              <RadioGroup aria-label="travel-time" value={field.value || null} onChange={field.onChange}>
                <Box display="flex" flexWrap="wrap">
                  <FormControlLabel
                    value={'15'}
                    control={<AppRadioButton color="primary" name="15 Min" />}
                    label=""
                  />
                  <FormControlLabel
                    value={'30'}
                    control={<AppRadioButton color="primary" name="30 Min" />}
                    label=""
                  />
                  <FormControlLabel
                    value={'60'}
                    control={<AppRadioButton color="primary" name="60 Min" />}
                    label=""
                  />
                  <FormControlLabel
                    value={null}
                    control={<AppRadioButton color="primary" name="60+ Min" />}
                    label=""
                  />
                  <FormControlLabel
                    value={'0'}
                    control={<AppRadioButton color="primary" name="Remote Only" />}
                    label=""
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          )}
          name={name}
          control={control}
        />
      </Box>
    </div>
  );
};
