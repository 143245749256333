import { FilterSection } from '@vyce/core/src/types';
import { SALARY_TYPES } from '@vyce/core/src/utils/job';

export const verifyModalText = 'Please validate your email';
export const MAX_YEARS_OF_EXPERIENCE = 10;

export const LIST_STEP = 6;

export const DEFAULT_CALCULATED_DISTANCE = null;
export const DEFAULT_DISTANCE = 75;
export const MIN_DISTANCE = 15;
const DEFAULT_STEP = 15;
const MAX_DISTANCE = 75;
export const POSSIBLE_DISTANCE = 60;
export const MINUTES_IN_HOUR = 60;
export const MILES_PER_HOUR = 8;

const markOptions = [
  {
    value: 15,
    label: '15 Min',
  },
  {
    value: 30,
    label: '30 Min',
  },
  {
    value: 45,
    label: '45 Min',
  },
  {
    value: 60,
    label: '60 Min',
  },
  {
    value: 75,
    label: '60+ Min',
  },
];

const yearsMarkOptions = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10+',
  },
];

export const FILTER_OPTIONS: FilterSection[] = [
  {
    title: 'Type of Contract',
    filters: [
      {
        type: 'checkbox',
        label: 'Contract',
        field: 'isContract',
        values: false,
        defaultValue: false,
      },
      {
        type: 'checkbox',
        label: 'Permanent',
        field: 'isPermanent',
        values: false,
        defaultValue: false,
      },
    ],
  },
  {
    title: 'Distance from job',
    filters: [
      {
        type: 'slider',
        label: 'Miles',
        field: 'distance',
        defaultValue: DEFAULT_DISTANCE,
        min: MIN_DISTANCE,
        max: MAX_DISTANCE,
        marks: markOptions,
        step: DEFAULT_STEP,
        hintMessage: 'Distance is calculated with the speed of 8 miles per hour.',
      },
    ],
  },
  {
    title: 'Years of experience',
    filters: [
      {
        type: 'slider',
        label: 'Years',
        field: 'experience',
        min: 0,
        max: MAX_YEARS_OF_EXPERIENCE,
        defaultValue: [0, MAX_YEARS_OF_EXPERIENCE],
        marks: yearsMarkOptions,
      },
    ],
  },
  {
    title: 'Start Date',
    filters: [
      {
        type: 'checkbox',
        label: 'ASAP',
        field: 'urgently',
        values: false,
        defaultValue: false,
      },
      {
        type: 'date',
        label: 'From',
        field: 'start_date_gte',
        values: null,
        defaultValue: null,
      },
      {
        type: 'date',
        label: 'To',
        field: 'start_date_lte',
        values: null,
        defaultValue: null,
      },
    ],
  },
  {
    title: 'Salary',
    filters: [
      {
        type: 'select',
        multiple: false,
        label: 'Type',
        field: 'salary_type',
        values: SALARY_TYPES,
        defaultValue: 'Hour',
      },
      {
        type: 'input',
        dataType: 'text',
        label: 'From',
        field: 'salary_from',
        values: '',
        defaultValue: '',
      },
      {
        type: 'input',
        dataType: 'text',
        label: 'To',
        field: 'salary_to',
        values: '',
        defaultValue: '',
      },
    ],
  },
];
