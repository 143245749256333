import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { UserPreview } from '@vyce/core/src/views/preview/user/UserPreview';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';

import { useTypedSelector } from '../../hooks';

export const Preview: React.FC = () => {
  const { user } = useTypedSelector((state) => state);
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();

  const goToEditView = () => {
    history.push('/profile/personal');
  };

  return (
    <Box padding={isMobile ? 1 : 5}>
      <UserPreview
        goToEditView={goToEditView}
        userPreviewData={{
          user,
          qualifications: user.worker?.qualifications,
        }}
      />
    </Box>
  );
};
