import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { AuthLayout } from '@vyce/core/src/views/auth/AuthLayout';
import { VerifyPhone } from '@vyce/core/src/views/auth/components/VerifyPhone';
import { ForgotPassword } from '@vyce/core/src/views/auth/components/ForgotPassword';
import config from '@vyce/core/src/assets/config';

import { WorkerRegister } from './WorkerRegister';
import { WorkerLogin } from './WorkerLogin';
import { useTypedSelector, useActions } from '../../hooks';

export enum AUTH_PATHS {
  SIGNUP = '/auth/signup',
  LOGIN = '/auth/login',
  VERIFY_PHONE = '/auth/verify-phone',
  FORGOT_PASSWORD = '/auth/forgot-password/:token',
}

export const WorkerAuth: React.FC = (props: any) => {
  const { userDataLoading, access_token } = useTypedSelector(state => state.helper);
  const { verifyPhone } = useActions();
  const redirectUrl = props?.location?.state?.from || '/onboarding';

  return (
    <AuthLayout rightPictureSrc={config.auth.connect}>
      <Switch>
        <Route path={AUTH_PATHS.SIGNUP} component={WorkerRegister} />
        <Route path={AUTH_PATHS.LOGIN} component={WorkerLogin} />
        <Route path={AUTH_PATHS.FORGOT_PASSWORD} component={() => <ForgotPassword />} />
        <Route
          path={AUTH_PATHS.VERIFY_PHONE}
          component={() => (
            <VerifyPhone loading={userDataLoading} redirectUrl={redirectUrl} verifyPhone={verifyPhone} />
          )}
        />
      </Switch>
    </AuthLayout>
  );
};
