import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

import '@vyce/core/src/index.css';
import { Fallback } from '@vyce/core/src/components';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <ErrorBoundary FallbackComponent={Fallback}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
