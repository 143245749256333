import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import config, { ENVIRONMENTS } from '@vyce/core/src/api/config';

import reducers from './reducers';

const enhancers = [applyMiddleware(thunk)];

if (config.environment !== ENVIRONMENTS.PROD) {
  // @ts-ignore
  const devToolsExtension = window?.__REDUX_DEVTOOLS_EXTENSION__;

  if (devToolsExtension) {
    enhancers.push(devToolsExtension());
  }
}
// @ts-ignore
export const store = createStore(reducers, {}, compose(...enhancers));
