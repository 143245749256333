import React, { useContext } from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { useStyles } from '@vyce/core/src/views/hiring/styles';
import { ButtonTitleWithLoading } from '@vyce/core/src/components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

interface Props {
  open: boolean;
  loading: boolean;
  setOpen: Function;
  cancelApplication: () => void;
}

export const CancelApplicationDialog: React.FC<Props> = ({ open, setOpen, cancelApplication, loading }) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Are you sure you want to cancel application?</DialogTitle>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
          No, take me back
        </Button>
        <Button
          size="large"
          style={{ width: 179 }}
          className={classes.redButton}
          onClick={cancelApplication}
          variant="outlined">
          <ButtonTitleWithLoading title="Yes, I want to cancel" loaderVariant="primary" loading={loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
