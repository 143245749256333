import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { LatLngExpression } from 'leaflet';
import debounce from 'lodash/debounce';

import { useHistory, useParams } from 'react-router-dom';

import { FilterSection, Job, MapMarker, TagGroups } from '@vyce/core/src/types';
import {
  acceptEmployerApplicationRequest,
  applyJobRequest,
  cancelApplicationRequest,
  cancelWorkerDeclinationRequest,
  declineConnectionRequest,
  getJobsRequest,
  getVacanciesRequest,
  getVacancyRequest,
} from '@vyce/core/src/api/connect';
import { fetchTagsRequest } from '@vyce/core/src/api/handbook';
import { DEFAULT_JOBS_SORTING, getJobsSortingObject } from '@vyce/core/src/utils/sorting';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { DeviceContext } from '@vyce/core/src/contexts';
import defaultMarkerPicture from '@vyce/core/src/assets/company-icon.png';
import { INVALID_DATE_VALUE } from '@vyce/core/src/modules/hiringModule/config';
import { MAP_MODE_LIMIT } from '@vyce/core/src/views/map/constants';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../../hooks';
import { FilterOptions, InitialFilterOptions } from '../types';

import {
  FILTER_OPTIONS,
  MILES_PER_HOUR,
  MINUTES_IN_HOUR,
  POSSIBLE_DISTANCE,
  MAX_YEARS_OF_EXPERIENCE,
  LIST_STEP,
  DEFAULT_CALCULATED_DISTANCE,
  verifyModalText,
} from '../config';

export const useJobsData = () => {
  const { handleServerError } = useContext(NotificationContext);
  const [filterSections, setFilterSections] = useState<FilterSection[]>(FILTER_OPTIONS);
  const { access_token } = useTypedSelector(state => state.helper);
  const { user } = useTypedSelector(state => state);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [page, setPage] = useState(0);
  const [mapMode, setMapMode] = useState<boolean>(false);
  const [jobsCount, setJobsCount] = useState<number>(0);
  const [selectedJobId, setSelectedJobId] = useState<string | undefined>();
  const [connectedJob, setConnectedJob] = useState<Job>();
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [selectedMarker, setSelectedMarker] = useState<MapMarker>();
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const [filters, setFilters] = useState<FilterOptions>();
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [sorting, setSorting] = useState<any>(DEFAULT_JOBS_SORTING);
  const [openJobDetails, setOpenJobDetails] = React.useState(false);
  const [openApplyJobDialog, setOpenApplyJobDialog] = useState<boolean>(false);
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(true);
  const [cancelApplicationDialogOpen, setCancelApplicationDialogOpen] = useState<boolean>(false);
  const [declineApplicationDialogOpen, setDeclineApplicationDialogOpen] = useState<boolean>(false);
  const [cancelDeclinationDialogOpen, setCancelDeclinationDialogOpen] = useState<boolean>(false);
  const [acceptDialogOpen, setAcceptDialogOpen] = useState<boolean>(false);
  const [successConnectionDialogOpen, setSuccessConnectionDialogOpen] = useState<boolean>(false);
  const { job, category = 'matched' } = useParams<{ job: string; category: string }>();
  const history = useHistory();
  const { isMobile } = useContext(DeviceContext);

  const pageCount = Math.ceil(jobsCount / LIST_STEP);

  const paginationOptions = {
    page,
    pageSize: LIST_STEP,
    pageCount,
    rowCount: jobsCount,
    setPage,
  };

  const applyJob = async (message: string) => {
    if (!selectedJobId) {
      return;
    }
    try {
      await applyJobRequest(access_token, selectedJobId, message);
      await updateInfo();
      setOpenApplyJobDialog(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const cancelApplication = async () => {
    if (!selectedJobId) {
      return;
    }
    try {
      await cancelApplicationRequest(access_token, selectedJobId);
      await updateInfo();
      setCancelApplicationDialogOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const declineApplication = async () => {
    if (!selectedJobId) {
      return;
    }
    try {
      await declineConnectionRequest(access_token, selectedJobId);
      await updateInfo();
      setDeclineApplicationDialogOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const cancelDeclination = async () => {
    if (!selectedJobId) {
      return;
    }
    try {
      await cancelWorkerDeclinationRequest(access_token, selectedJobId);
      await updateInfo();
      setCancelDeclinationDialogOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const acceptApplication = async () => {
    if (!selectedJobId) {
      return;
    }
    try {
      await acceptEmployerApplicationRequest(access_token, selectedJobId);
      await updateInfo();
      setConnectedJob(jobs.find(job => job.uuid === selectedJobId));
      setAcceptDialogOpen(false);
      setSuccessConnectionDialogOpen(true);
    } catch (e) {
      handleServerError(e);
    }
  };

  const updateInfo = async () => {
    if (!selectedJobId) {
      return;
    }
    await getJobs();

    if (job) {
      const jobUrlItems = getUrlItems(job);
      const jobId = jobUrlItems?.id;
      await getJob(jobId);
    }
  };

  const getJobs = async () => {
    const start_date_lte = filters?.start_date_lte || null;
    const start_date_gte = filters?.start_date_gte || null;
    if (start_date_gte === INVALID_DATE_VALUE || start_date_lte === INVALID_DATE_VALUE) {
      return;
    }
    const data = {
      keywords: search,
      lon: user.address?.lon,
      lat: user.address?.lat,
      distance_type: 'mile',
      distance: DEFAULT_CALCULATED_DISTANCE,

      ...filters,
      start_date_lte,
      start_date_gte,
      order_by: [sorting],
      salary_type: filters?.salary_type?.toLowerCase() || 'hour',
      tags: user.worker?.tags || [],
      category,
      limit: mapMode ? MAP_MODE_LIMIT : LIST_STEP,
      offset: mapMode ? 0 : page * LIST_STEP,
    };
    try {
      setLoading(true);
      let res: any;
      if (category && category !== 'matched') {
        res = await getVacanciesRequest(access_token, data);
      } else {
        res = await getJobsRequest(access_token, data);
      }
      setJobs(res.data?.items);
      setJobsCount(res.data?.count);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      const description = e?.response?.data?.detail?.description ?? '';
      const isVerificationModal = description.includes(verifyModalText);
      if (isVerificationModal && showVerificationModal) {
        setShowVerificationModal(false);
      }
      if (isVerificationModal && !showVerificationModal) return;
      handleServerError(e);
    }
  };

  const getJob = async (jobId: string) => {
    try {
      const res = await getVacancyRequest(access_token, jobId);
      setSelectedJob(res.data);
    } catch (e) {
      handleServerError(e);
    }
  };

  const getSkills = async () => {
    const res = await fetchTagsRequest({ group: TagGroups.SKILL });
    setFilterSections(sections => {
      return sections.map(section => {
        if (section.title === 'Professional requirements') {
          return {
            ...section,
            filters: section.filters.map(filter => {
              if (filter.field === TagGroups.SKILL) {
                return {
                  ...filter,
                  values: res.data,
                };
              }
              return filter;
            }),
          };
        }
        return section;
      });
    });
  };

  useEffect(() => {
    const selectedMarker: MapMarker | undefined = selectedJob
      ? {
          lat: selectedJob.address?.lat,
          lon: selectedJob.address?.lon,
          id: selectedJob.uuid,
          name: selectedJob.name as string,
          picture: selectedJob.company?.logo?.url || defaultMarkerPicture,
        }
      : undefined;

    let markers = jobs.map(job => ({
      lat: job.address?.lat,
      lon: job.address?.lon,
      name: job.name as string,
      id: job.uuid,
      picture: job.company?.logo?.url || defaultMarkerPicture,
    }));

    if (selectedMarker) {
      markers = markers.filter(marker => marker.id !== selectedMarker.id);
    }

    setMarkers(markers);
    setSelectedMarker(selectedMarker);
  }, [selectedJob, jobs]);

  useEffect(() => {
    const jobUrlItems = getUrlItems(job);
    const id = jobUrlItems?.id;
    setOpenJobDetails(!!id);
    if (id) {
      getJob(id);
    } else {
      setSelectedJob(undefined);
    }
  }, [job]);

  useEffect(() => {
    getSkills();
  }, [category, mapMode]);

  useEffect(() => {
    getJobs();
  }, [mapMode, page]);

  useEffect(() => {
    setPage(0);
    getJobs();
  }, [category, search, sorting, filters]);

  const sort = (option: string) => {
    const sortData = getJobsSortingObject(option);
    setSorting(sortData);
  };

  const handleSearchChange = useCallback((event: any) => {
    setSearch(event.target.value);
  }, []);

  const debouncedHandleSearchChange = useMemo(() => debounce(handleSearchChange, 500), [handleSearchChange]);

  const formatSalaryValue = (value: string | number, type: string): number | undefined => {
    if (type === 'Negotiable' || isNaN(+value)) {
      return undefined;
    }
    return +value;
  };

  const handleFilterChange = useCallback((newFilter: InitialFilterOptions) => {
    const {
      experience = [],
      isContract,
      isPermanent,
      salary_from = '',
      salary_to = '',
      skills = [],
      urgently,
      distance,
      salary_type,
    } = newFilter;

    const newDistance =
      distance && distance <= POSSIBLE_DISTANCE ? (distance / MINUTES_IN_HOUR) * MILES_PER_HOUR : null;

    const newFilters = {
      ...filters,
      ...newFilter,
      urgently: urgently || null,
      years_of_experience_lte: experience[1] === MAX_YEARS_OF_EXPERIENCE ? 100 : experience[1],
      years_of_experience_gte: experience[0],
      salary_from: formatSalaryValue(salary_from, salary_type),
      salary_to: formatSalaryValue(salary_to, salary_type),
      type:
        (isContract && isPermanent) || (!isContract && !isPermanent)
          ? ''
          : isContract
          ? 'contract'
          : 'permanent',
      tags: skills,
      distance: newDistance,
    };
    setFilters(newFilters);
  }, []);

  const debouncedHandleFilter = useMemo(() => debounce(handleFilterChange, 500), []);

  const back = () => {
    history.push(`/jobs/${category}`);
  };

  const goToJob = (id: string, name: string) => {
    history.push(`/jobs/${category}/${name}_${id}`);
  };

  const getMyPosition = (): { position: LatLngExpression | undefined; popupText: string } => {
    return {
      position: user.address?.lon && user.address?.lat ? [user.address?.lat, user.address?.lon] : undefined,
      popupText: 'Me',
    };
  };

  const openApplyDialog = (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => {
    event.stopPropagation();
    setSelectedJobId(jobId);
    setOpenApplyJobDialog(true);
  };

  const openCancelApplicationDialog = (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => {
    event.stopPropagation();
    setSelectedJobId(jobId);
    setCancelApplicationDialogOpen(true);
  };

  const openAcceptDialog = (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => {
    event.stopPropagation();
    setSelectedJobId(jobId);
    setAcceptDialogOpen(true);
  };

  const openDeclineConnectionDialog = (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => {
    event.stopPropagation();
    setSelectedJobId(jobId);
    setDeclineApplicationDialogOpen(true);
  };

  const openCancelDeclinationDialog = (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => {
    event.stopPropagation();
    setSelectedJobId(jobId);
    setCancelDeclinationDialogOpen(true);
  };

  return {
    isMobile,
    openJobDetails,
    mapMode,
    jobsCount,
    filterSections,
    debouncedHandleFilter,
    markers,
    selectedMarker,
    paginationOptions,
    loading,
    jobs,
    selectedJob,
    user,
    connectedJob,
    successConnectionDialogOpen,
    acceptDialogOpen,
    cancelDeclinationDialogOpen,
    declineApplicationDialogOpen,
    cancelApplicationDialogOpen,
    openApplyJobDialog,
    history,
    applyJob,
    setOpenApplyJobDialog,
    setCancelApplicationDialogOpen,
    cancelApplication,
    setDeclineApplicationDialogOpen,
    declineApplication,
    setCancelDeclinationDialogOpen,
    cancelDeclination,
    setAcceptDialogOpen,
    acceptApplication,
    setConnectedJob,
    setSuccessConnectionDialogOpen,
    back,
    openCancelDeclinationDialog,
    openAcceptDialog,
    openApplyDialog,
    openCancelApplicationDialog,
    openDeclineConnectionDialog,
    goToJob,
    getMyPosition,
    sort,
    setMapMode,
    handleSearchChange: debouncedHandleSearchChange,
  };
};
