import React from 'react';
import { Box } from '@material-ui/core';

import { Image } from '../types';
import { ImageUploader } from './ImageUploader';
import { OnboardingStepTitle } from './OnboardingStepTitle';

interface Props {
  onImageUpload?: (avatar: Image) => void;
  avatar?: Image;
}

export const ProfilePhoto: React.FC<Props> = ({ onImageUpload, avatar }) => {
  return (
    <Box position="relative">
      <OnboardingStepTitle text="Upload Profile Photo." />

      <Box marginTop={2}>
        <ImageUploader image={avatar} onImageUpload={onImageUpload} extraText="profile photo" />
      </Box>
    </Box>
  );
};
