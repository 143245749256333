import React, { useContext, useEffect, useRef, useState } from 'react';

import { Box, Button, Dialog } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import {
  ActiveOnboardingStep,
  Image,
  OnboardingStep,
  RequestWorkerData,
  Tag,
  TaskNames,
  User,
  WorkerOnboardingForm,
} from '@vyce/core/src/types';
import { HomeAddressStep, Greetings, NINumberStep } from '@vyce/core/src/components/onboarding';
import { TravelTimeForm } from '@vyce/core/src/views/profile/components/TravelTimeForm';
import { ProfilePhoto, AppOnboarding, OnboardingStepTitle, HowItWorks } from '@vyce/core/src/components';
import { getLocationDetails } from '@vyce/core/src/utils/location';
import { LocationField } from '@vyce/core/src/components/controlled-inputs';
import { MAIN_CONTAINER_ID, SEARCH_STATUSES } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts';
import { triggerCheckerRequest } from '@vyce/core/src/api/checkers';
import { checkNINumber } from '@vyce/core/src/utils/onboarding';
import {
  clearOnboardingLSData,
  saveOnboardingDataToLS,
  setOnboardingData,
} from '@vyce/core/src/utils/local-storage';

import { JobsForm } from './components/JobsForm';
import { AvailabilityToWorkForm } from './components/AvailabilityToWorkForm';
import { NationalityForm } from './components/NationalityForm';
import { WorkerExperienceForm } from './components/WorkerExperienceForm';
import { useActions, useTypedSelector } from '../../hooks';
import { SpecificSkillsForm } from './components/SpecificSkillsForm';
import { ONBOARDING_MESSAGES, WORKER_STEPS } from './constants';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

export const WorkerOnboarding: React.FC = () => {
  const { user } = useTypedSelector(state => state);
  const methods = useForm<WorkerOnboardingForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      address: undefined,
      workerAddress: '',
      travel_time: '',
      jobs: [],
      ni_number: '',
      date_of_birth: '',
      languages: [],
      positions: [],
      search_status: SEARCH_STATUSES[0].name,
    },
  });
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<string>(ONBOARDING_MESSAGES[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newAvatar, setNewAvatar] = useState<Image | undefined>();
  const { uploadUserAvatar, createWorker, updateUser } = useActions();
  const { showNotification, handleServerError } = useContext(NotificationContext);
  const childRef = useRef<any>();
  const { first_name, last_name } = user;
  const { isMobile } = useContext(DeviceContext);
  const { watch, setValue } = methods;
  const fullAddress = watch('address.address_snippet');
  const languages = watch('languages');
  const specific_skills = watch('specific_skills');

  const handleImageUpload = (avatar: Image) => {
    setNewAvatar(avatar);
    setCurrentMessage(ONBOARDING_MESSAGES[2]);
  };

  const skipWorkLocation = () => {
    methods.setValue('workerAddress', methods.getValues('address.address_snippet') || '');
  };

  const scrollDown = () => {
    childRef?.current?.scrollDown();
  };

  const steps: OnboardingStep[] = [
    {
      name: 'Greetings',
      component: (
        <Greetings firstName={first_name} subtitle=" Now, let’s quickly create you an awesome profile!" />
      ),
    },
    {
      name: 'JobsForm',
      component: <JobsForm />,
    },
    {
      name: 'NINumberForm',
      component: (
        <NINumberStep
          detailsInfo="  We ask for your NI number so we can validate your industry cards and qualifications."
          title="Please enter your NI number."
        />
      ),
    },
    {
      name: 'ProfilePhoto',
      component: <ProfilePhoto onImageUpload={handleImageUpload} avatar={newAvatar} />,
    },
    {
      name: 'LocationForm',
      component: <HomeAddressStep />,
    },
    {
      name: 'WhereLookingWork',
      component: (
        <Box>
          <OnboardingStepTitle text="Where are you looking for work?" />
          <LocationField name="workerAddress" margin="normal" />

          <Box display="flex" flexDirection="column" alignItems="flex-end" marginTop={2}>
            <Button onClick={skipWorkLocation} variant="outlined" color="primary">
              Near my home
            </Button>
          </Box>
        </Box>
      ),
    },
    {
      name: 'TravelTimeForm',
      component: <TravelTimeForm />,
    },
    {
      name: 'NationalityForm',
      component: <NationalityForm />,
    },
    {
      name: 'WorkerExperienceForm',
      component: <WorkerExperienceForm scrollDown={scrollDown} />,
    },
    {
      name: 'SpecificSkillsForm',
      component: <SpecificSkillsForm />,
    },
    {
      name: 'AvailabilityToWorkForm',
      component: <AvailabilityToWorkForm />,
    },
  ];
  const [activeStep, setActiveStep] = useState<ActiveOnboardingStep>({
    step: steps[0],
    number: 0,
  });

  useEffect(() => {
    if (fullAddress) {
      checkAddress(fullAddress);
    }
  }, [fullAddress]);

  useEffect(() => {
    scrollDown();
  }, [languages, specific_skills]);

  useEffect(() => {
    const status = setOnboardingData(methods.setValue, setActiveStep, nextStep, steps);
    if (!status) {
      setOpen(true);
    }
    return () => {
      saveOnboardingDataToLS(methods.getValues(), activeStep.number);
    };
  }, []);

  const checkAddress = async (address: string) => {
    setLoading(true);
    const addressData = await getLocationDetails({ fullAddress: address });
    setValue('address.address_line_2', addressData.address_line_2 || '');
    setValue('address.address_line_1', addressData.address_line_1 || '');
    setValue('address.address_line_3', addressData.address_line_3 || '');
    setLoading(false);
    if (!addressData.address_line_4) {
      showNotification({
        message: 'Please input full address',
        options: {
          variant: 'error',
        },
      });
      return;
    }
  };

  window.onbeforeunload = () => {
    saveOnboardingDataToLS(methods.getValues(), activeStep.number);
  };

  const nextStep = (jumpTo?: number) => {
    childRef?.current?.nextStep(jumpTo);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = async (data: WorkerOnboardingForm) => {
    switch (activeStep.step.name) {
      case 'AvailabilityToWorkForm':
        setLoading(true);
        const worker = await prepareWorkerData(data);
        await createWorker(worker, handleServerError);
        clearOnboardingLSData();
        setLoading(false);
        break;
      case 'JobsForm':
        setCurrentMessage(ONBOARDING_MESSAGES[1]);
        nextStep();
        break;
      case 'NINumberForm':
        if (!data.ni_number) {
          setCurrentMessage(ONBOARDING_MESSAGES[2]);
          return nextStep();
        }
        const isNINumberAvailable = await checkNINumber(data.ni_number, showNotification);
        if (!isNINumberAvailable) {
          return;
        }
        setCurrentMessage(ONBOARDING_MESSAGES[2]);
        nextStep();
        break;
      case 'ProfilePhoto':
        if (newAvatar?.file) {
          setLoading(true);
          await uploadUserAvatar({ file: newAvatar.file, saveToProfile: true, handleServerError });
        }
        setLoading(false);
        nextStep();
        setCurrentMessage(ONBOARDING_MESSAGES[4]);
        break;
      case 'LocationForm':
        setCurrentMessage(ONBOARDING_MESSAGES[5]);
        nextStep();
        break;
      case 'WhereLookingWork':
        setCurrentMessage(ONBOARDING_MESSAGES[6]);
        nextStep();
        break;
      case 'NationalityForm':
        setLoading(true);
        const userData = await prepareUserData(data);
        await updateUser({ data: userData, isNotify: false, handleServerError, showNotification });
        nextStep();
        setLoading(false);
        break;
      case 'WorkerExperienceForm':
        setCurrentMessage(ONBOARDING_MESSAGES[8]);
        nextStep();
        break;
      case 'SpecificSkillsForm':
        setCurrentMessage(`Great work ${first_name}! You’re profile is all done`);
        nextStep();
        break;
      default:
        nextStep();
        break;
    }
  };

  const prepareUserData = async (data: WorkerOnboardingForm): Promise<Partial<User>> => {
    const address = await getLocationDetails({
      fullAddress: data.address.address_snippet || '',
      street: data.address.address_line_2,
      houseNumber: data.address.address_line_1,
      town: data.address.address_line_3,
    });
    const userData: Partial<User> = {
      address,
      nationality: data.nationality,
      date_of_birth: data.date_of_birth,
    };
    if (data.ni_number) {
      userData.context = {
        ni_number: data.ni_number,
      };
    }
    return userData;
  };

  const prepareWorkerData = async (data: WorkerOnboardingForm): Promise<RequestWorkerData> => {
    const tags: Tag[] = [];
    if (data.jobs) {
      tags.push(...data.jobs);
    }
    if (data.qualifications) {
      tags.push(...data.qualifications);
    }
    if (data.specific_skills) {
      tags.push(...data.specific_skills);
    }
    const address = await getLocationDetails({ fullAddress: data.workerAddress });
    const travel_time = data?.travel_time ? +data?.travel_time : null;
    return {
      tags,
      address,
      positions: data.positions || [],
      languages: data.languages || [],
      travel_time,
      search_status: SEARCH_STATUSES.find(item => item.name === data.search_status)?.group as string,
    };
  };

  return (
    <>
      <AppOnboarding
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        methods={methods}
        currentMessage={currentMessage}
        handleNext={handleNext}
        steps={steps}
        loading={loading}
        ref={childRef}
      />

      <Dialog
        fullScreen={isMobile}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <HowItWorks steps={WORKER_STEPS} handleClose={handleClose} />
      </Dialog>
    </>
  );
};
