import { HelperState } from '@vyce/core/src/types';

import { HelperAction } from '../actions/helper';
import { ActionType } from '../action-types';
import { DEFAULT_NAV_ITEMS } from '../../views/main/constants';

const initialState: HelperState = {
  userDataLoading: false,
  notification: undefined,
  redirectTo: '',
  navItems: DEFAULT_NAV_ITEMS,
  access_token: '',
  showTour: false,
};

const reducer = (state: HelperState = initialState, action: HelperAction): HelperState => {
  switch (action.type) {
    case ActionType.SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.payload,
      };
    case ActionType.CLEAR_REDIRECT_TO:
      return {
        ...state,
        redirectTo: '',
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        userDataLoading: action.payload,
      };
    case ActionType.SET_NAV_ITEMS:
      return {
        ...state,
        navItems: action.payload,
      };
    case ActionType.CLEAR_NAV_ITEMS:
      return {
        ...state,
        navItems: [],
      };
    case ActionType.SET_ACCESS_TOKEN:
      return {
        ...state,
        access_token: action.payload,
      };
    case ActionType.CLEAR_HELPERS:
      return initialState;
    case ActionType.SET_SHOW_TOUR:
      return {
        ...state,
        showTour: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
