import React, { MouseEventHandler, ReactElement } from 'react';

import { Box, Typography } from '@material-ui/core';

import { Job, Tag } from '../../types';
import { BackButton } from '../../components/BackButton';
import { JobHeader, JobDetails, JobContactDetails } from './components';
import { useStyles } from './styles';

interface Props {
  job?: Job;
  isPreview?: boolean;
  back: MouseEventHandler<HTMLButtonElement>;
  children?: ReactElement;
  workerTags?: Tag[];
}

export const JobPage: React.FC<Props> = ({ children, job, back, isPreview = false, workerTags = [] }) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="flex-end" padding={1}>
        <BackButton back={back} />
      </Box>

      <Box padding={2} paddingTop={0} paddingBottom={0}>
        <JobHeader job={job} />
      </Box>

      {job?.computed?.connection?.status === 'connected' && <JobContactDetails job={job} />}

      <JobDetails job={job} workerTags={workerTags} />

      <Box padding={2}>
        <Box marginBottom={2}>
          <Typography variant="h6" className={classes.bold}>
            Job Description
          </Typography>
        </Box>

        <Typography>{job?.description}</Typography>
      </Box>

      {!isPreview && (
        <Box padding={2} className={classes.bottomButtonContainer}>
          {children}
        </Box>
      )}
    </>
  );
};
