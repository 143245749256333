import React, { ReactElement } from 'react';

import { Avatar, Box, Typography, useTheme } from '@material-ui/core';
import { BiTimeFive } from 'react-icons/bi';
import { FiBriefcase } from 'react-icons/fi';
import { FcMoneyTransfer } from 'react-icons/fc';
import { Theme } from '@material-ui/core/styles';
import TimeAgo from 'timeago-react';
import { Link, useHistory } from 'react-router-dom';
import defaultCompanyPicture from '@vyce/core/src/assets/company-icon.png';

import { useStyles } from '../styles';
import { Job } from '../../../types';
import { getJobSalary } from '../../../utils/job';

interface Props {
  job?: Job;
  children?: ReactElement;
}

export const JobHeader: React.FC<Props> = ({ children, job }) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const history = useHistory();

  return (
    <Box>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Avatar className={classes.avatar} src={job?.company?.logo?.url}>
          {!job?.company?.logo?.url && <img height={50} alt="company" src={defaultCompanyPicture} />}
        </Avatar>

        <Box marginLeft={2}>
          <Typography
            component={Link}
            to={`${history.location.pathname}/${job?.company?.name}_${job?.company?.uuid}`}
            className={classes.companyName}
            variant="subtitle1">
            {job?.company?.name}
          </Typography>
          <Typography variant="subtitle2" color="primary" className={classes.textWithIcon}>
            <FiBriefcase size="15px" className={classes.iconWithText} /> {job?.name as string}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.textWithIcon}
            style={{ color: theme.palette.success.main }}>
            <FcMoneyTransfer size="15px" className={classes.iconWithText} />
            {getJobSalary(job?.salary_from, job?.salary_to, job?.salary_type)}
          </Typography>
          {job?.overtime_type && (
            <Typography
              variant="subtitle2"
              className={classes.textWithIcon}
              style={{ color: theme.palette.success.main }}>
              <FcMoneyTransfer size="15px" className={classes.iconWithText} />
              {getJobSalary(job?.overtime_from, job?.overtime_to, job?.overtime_type)} (overtime)
            </Typography>
          )}
          {job?.created_at && (
            <Typography variant="subtitle2" color="textSecondary" className={classes.textWithIcon}>
              <BiTimeFive size="15px" className={classes.iconWithText} />
              <TimeAgo datetime={job.created_at} />
            </Typography>
          )}
        </Box>
      </Box>

      {children}
    </Box>
  );
};
