import React from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { useStyles } from '@vyce/core/src/views/hiring/styles';
import { CONNECT_EVENTS, Job } from '@vyce/core/src/types';

interface Props {
  job: Job;
  openApplyDialog: (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => void;
  openCancelApplicationDialog: (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => void;
  openDeclineConnectionDialog: (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => void;
  openAcceptDialog: (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => void;
  openCancelDeclinationDialog: (event: React.MouseEvent<HTMLButtonElement>, jobId: string) => void;
  isJobPage?: boolean;
}

export const ConnectButtons: React.FC<Props> = ({
  job,
  openApplyDialog,
  openCancelApplicationDialog,
  openAcceptDialog,
  openCancelDeclinationDialog,
  openDeclineConnectionDialog,
  isJobPage = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {!job.computed?.connection?.event && (
        <Button
          onClick={(event) => openApplyDialog(event, job.uuid)}
          variant="contained"
          fullWidth={isJobPage}
          color="primary">
          Apply
        </Button>
      )}

      {job.computed?.connection?.event === CONNECT_EVENTS.applied_by_worker && (
        <Box width={isJobPage ? '100%' : 'auto'}>
          {isJobPage ? (
            <Box display="flex" width={isJobPage ? '100%' : 'auto'} gridGap={16}>
              <Button
                onClick={(event) => openCancelApplicationDialog(event, job.uuid)}
                variant="outlined"
                size="medium"
                fullWidth
                className={classes.redButton}>
                Cancel
              </Button>
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                disableTouchRipple
                disabled
                className={classes.disabledButton}
                fullWidth>
                Applied
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              disabled
              className={classes.disabledButton}>
              Applied
            </Button>
          )}
        </Box>
      )}

      {(job.computed?.connection?.event === CONNECT_EVENTS.connected_by_employer ||
        job.computed?.connection?.event === CONNECT_EVENTS.connected_by_worker) && (
        <>
          <Button
            variant="contained"
            fullWidth={isJobPage}
            disabled
            className={classes.connectedButton}
            color="primary">
            Connected
          </Button>
        </>
      )}

      {job.computed?.connection?.event === CONNECT_EVENTS.applied_by_employer && (
        <Box display="flex" width={isJobPage ? '100%' : 'auto'} gridGap={16}>
          <Button
            fullWidth={isJobPage}
            variant="outlined"
            onClick={(event) => openDeclineConnectionDialog(event, job.uuid)}
            className={classes.redButton}>
            Decline
          </Button>
          <Button
            onClick={(event) => openAcceptDialog(event, job.uuid)}
            fullWidth={isJobPage}
            variant="contained"
            className={classes.acceptButton}>
            Accept
          </Button>
        </Box>
      )}

      {job.computed?.connection?.event === CONNECT_EVENTS.declined_by_worker && (
        <Button
          onClick={(event) => openCancelDeclinationDialog(event, job.uuid)}
          size={isJobPage ? 'medium' : 'small'}
          fullWidth={isJobPage}
          variant="contained"
          className={classes.warningButton}>
          Cancel Declination
        </Button>
      )}

      {job.computed?.connection?.event === CONNECT_EVENTS.declined_by_employer && (
        <Typography
          variant="subtitle2"
          style={{ lineHeight: '40px', color: theme.palette.error.main }}>
          Declined
        </Typography>
      )}
    </>
  );
};
