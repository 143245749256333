import React from 'react';

import { ThemeProvider } from '@material-ui/core';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import { DeviceProvider } from '@vyce/core/src/contexts';
import { AppSnackbarProvider } from '@vyce/core/src/components';
import { useAppTheme } from '@vyce/core/src/hooks/useAppTheme';
import { useDeviceData } from '@vyce/core/src/hooks/useDeviceData';
import NotificationProvider from '@vyce/core/src/contexts/notificationContext';

import { Main } from './views/main/Main';
import { store } from './store';

const App = () => {
  const { changeColorTheme, themeType, colorTheme, theme } = useAppTheme();
  const { isMobile, isDesktop, isLargeDesktop, iOS } = useDeviceData();

  return (
    <div id="app">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <DeviceProvider value={{ isMobile, isDesktop, isLargeDesktop, iOS }}>
            <Router
              getUserConfirmation={() => {
                /* Empty callback to block the default browser prompt */
              }}>
              <NotificationProvider>
                <AppSnackbarProvider>
                  <Main themeType={themeType} changeColorTheme={changeColorTheme} colorTheme={colorTheme} />
                </AppSnackbarProvider>
              </NotificationProvider>
            </Router>
          </DeviceProvider>
        </Provider>
      </ThemeProvider>
    </div>
  );
};

export default App;
