import React from 'react';
import { SpecificSkillsField } from '@vyce/core/src/components/controlled-inputs/SpecificSkillsField';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

export const SpecificSkillsForm: React.FC = () => {
  const { helper } = useTypedSelector((state) => state);

  return (
    <div>
      <OnboardingStepTitle text="Add any skills, experience or specialisms you have..." />

      <SpecificSkillsField margin="normal" token={helper.access_token} />
    </div>
  );
};
