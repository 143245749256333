import React, { useContext } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { Box, Button, Tooltip } from '@material-ui/core';
import { RiEyeLine } from 'react-icons/ri';

import { WORKER_PROFILE_TABS } from '@vyce/core/src/views/profile/config';
import useStyles from '@vyce/core/src/views/profile/styles';
import { CommonNavBarBlock, AppIconButton } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';

import { RouteWorkerProfileComponent } from './RouteWorkerProfileComponent';
const PREVIEW_URL = '/profile';

export const WorkerProfile: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();

  const goToPreview = () => {
    history.push(PREVIEW_URL);
  };

  return (
    <Box position="relative">
      <CommonNavBarBlock tabItems={WORKER_PROFILE_TABS} />

      <Box padding={isMobile ? 1 : 5}>
        <Box marginBottom={isMobile ? 4 : 0} marginTop={isMobile ? 3 : 0}>
          <Tooltip title="Preview" placement="left">
            {isMobile ? (
              <Button fullWidth color="primary" variant="contained" component={Link} to={PREVIEW_URL}>
                Preview
              </Button>
            ) : (
              <AppIconButton variant="paper" onClick={goToPreview} className={classes.previewIcon}>
                <RiEyeLine />
              </AppIconButton>
            )}
          </Tooltip>
        </Box>

        <div className={classes.contentContainer}>
          <Switch>
            <Route path="*" component={RouteWorkerProfileComponent} />
          </Switch>
        </div>
      </Box>
    </Box>
  );
};
