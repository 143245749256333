import React, { memo, ReactElement } from 'react';
import { Job, Tag, TagGroups } from '@vyce/core/src/types';
import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from '@vyce/core/src/views/hiring/styles';
import { TileContainer } from '@vyce/core/src/views/hiring/components/TileContainer';
import { getJobSalary } from '@vyce/core/src/utils/job';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { FiBriefcase } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import defaultCompanyPicture from '@vyce/core/src/assets/company-icon.png';

interface Props {
  currentJob: Job;
  goToJob: (id: string, name: string) => void;
  workerTags?: Tag[];
  children: ReactElement;
}

const WorkerJobTileComponent: React.FC<Props> = ({
  children,
  currentJob,
  goToJob,
  workerTags = [],
}) => {
  const classes = useStyles();
  const { job } = useParams<{ job: string }>();
  const jobUrlItems = getUrlItems(job);
  const id = jobUrlItems?.id;
  const workerRoleNames: string[] = workerTags
    ?.filter((tag: Tag) => tag.group === TagGroups.ROLE)
    .map((tag) => tag.name);

  return (
    <TileContainer
      selected={id === currentJob.uuid}
      handleClick={() => goToJob(currentJob.uuid, currentJob.name as string)}>
      <>
        <Box display="flex" alignItems="center" marginBottom={2}>
          <Avatar className={classes.avatar} src={currentJob?.company?.logo?.url}>
            {!currentJob?.company?.logo && (
              <img height={50} alt="company" src={defaultCompanyPicture} />
            )}
          </Avatar>

          <Box marginLeft={2}>
            <Typography className={classes.bold} variant="subtitle1">
              {currentJob?.company?.name}
            </Typography>

            <Typography
              variant="subtitle2"
              color={
                workerRoleNames.includes(currentJob.name as string) ? 'primary' : 'textSecondary'
              }
              className={classes.textWithIcon}>
              <FiBriefcase size="15px" className={classes.iconWithText} />{' '}
              {currentJob.name as string}
            </Typography>
          </Box>
        </Box>

        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Job Type
            </Typography>
            <Typography className={classes.capitalize} variant="subtitle2">
              {currentJob.job_type}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Distance
            </Typography>
            {currentJob?.computed?.distance?.value && (
              <Typography variant="subtitle2">
                {currentJob.computed.distance.value} {currentJob.computed.distance.type}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Postcode
            </Typography>
            <Typography variant="subtitle2">
              {currentJob.address?.address_line_4 || 'Unknown'}
            </Typography>
          </Grid>
        </Grid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.controlArea}>
          <Typography variant="subtitle2" color="primary">
            {getJobSalary(currentJob.salary_from, currentJob.salary_to, currentJob.salary_type)}
          </Typography>

          {children}
        </Box>
      </>
    </TileContainer>
  );
};

export const WorkerJobTile = memo(WorkerJobTileComponent);
