import { Dispatch } from 'redux';

import { NavItem, User } from '@vyce/core/src/types';

import { ActionType } from '../action-types';
import { HelperAction } from '../actions/helper';
import { WORKER_NAV_ITEMS } from '../../views/main/constants';

export const updateNavItems = (user: User, dispatch: Dispatch<HelperAction>) => {
  let navItems: NavItem[] = [];
  if (user.uuid && user?.worker) {
    navItems = WORKER_NAV_ITEMS;
  }

  dispatch({
    type: ActionType.SET_NAV_ITEMS,
    payload: navItems,
  });
};

export const setLoading = (loading: boolean) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_LOADING,
      payload: loading,
    });
  };
};

export const clearRedirectTo = () => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.CLEAR_REDIRECT_TO,
    });
  };
};

export const setNavItems = (navItems: NavItem[]) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_NAV_ITEMS,
      payload: navItems,
    });
  };
};

export const setAccessToken = (token: string) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_ACCESS_TOKEN,
      payload: token,
    });
  };
};

export const clearHelpers = () => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.CLEAR_HELPERS,
    });
  };
};

export const setShowTour = (status: boolean) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_SHOW_TOUR,
      payload: status,
    });
  };
};
