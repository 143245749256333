import workerIcon1 from '../../assets/svg/how-it-works/icon-1.png';
import workerIcon2 from '../../assets/svg/how-it-works/icon-2.png';
import workerIcon4 from '../../assets/svg/how-it-works/icon-4.png';
import { HowItWorksStep } from '@vyce/core/src/types';

export const WORKER_STEPS: HowItWorksStep[] = [
  {
    label: 'You’ll soon be able to connect to jobs, colleagues and companies near you...',
    subtitle: 'Welcome to Vyce Connect!',
    imgPath: workerIcon1,
  },
  {
    label:
      'Start by building your professional profile and verifying any cards and qualifications you have.\n',
    subtitle: '',
    imgPath: workerIcon2,
  },
  {
    label:
      'You’ll then instantly get matched to relevant jobs near you and can ‘Apply’ with a click.',
    subtitle: '',
    imgPath: workerIcon4,
  },
];

export const ONBOARDING_MESSAGES = [
  'We will ask a few questions to create your profile.',
  'Adding NI number you will get employee benefits.',
  'Keep going!',
  'Wow! Your profile picture looks really good.',
  'Providing your address will help you find better jobs.',
  'Shorter travel time means you get to work sooner.',
  'Tell us more about you and we’ll find you the most suitable jobs.',
  'Your work experience help you to get job faster.',
  'Tell us about your skills to get the most relevant job offers.',
  'Amazing! One step left to complete your profile.',
];
