import React, { useContext } from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import { AiOutlinePhone } from 'react-icons/ai';
import { FiMail } from 'react-icons/fi';

import { CopyToClipboard } from '../../../components/CopyToClipboard';
import { DeviceContext } from '../../../contexts';
import { Job } from '../../../types';

import { useStyles } from '../styles';

interface Props {
  job: Job;
}

export const JobContactDetails = ({ job }: Props) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme();
  return (
    <Box padding={2} className={classes.borderTop}>
      <Box marginBottom={2}>
        <Typography variant="h6" className={classes.bold}>
          Contact Details
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" flexDirection="column" marginTop={2}>
        {job?.contact_name && (
          <Box marginBottom={1}>
            <Typography color="primary" variant="subtitle1">
              Contact Name: {job.contact_name}
            </Typography>
          </Box>
        )}

        {job?.contact_email && (
          <Box display="flex" alignItems="center">
            {!isMobile && (
              <FiMail
                style={{ marginRight: theme.spacing(1) }}
                size="20px"
                color={theme.palette.primary.main}
              />
            )}
            <Typography color="primary" align="center" variant="subtitle1">
              {job.contact_email}
            </Typography>
            <CopyToClipboard text={job.contact_email} color={theme.palette.primary.main} />
          </Box>
        )}

        {job?.contact_phone && (
          <Box marginTop={1} display="flex" alignItems="center">
            {!isMobile && (
              <AiOutlinePhone
                style={{ marginRight: theme.spacing(1) }}
                size="20px"
                color={theme.palette.primary.main}
              />
            )}
            <Typography color="primary" align="center" variant="subtitle1">
              {job.contact_phone}
            </Typography>
            <CopyToClipboard text={job.contact_phone} color={theme.palette.primary.main} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
