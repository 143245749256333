import { NavItem } from '@vyce/core/src/types';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { FiBriefcase, FiUser } from 'react-icons/fi';

export const WORKER_NAV_ITEMS: NavItem[] = [
  { name: 'Dashboard', icon: HiOutlineViewGrid, link: '/dashboard' },
  {
    name: 'Jobs',
    icon: FiBriefcase,
    link: '/jobs',
    breadcrumb: 'jobs',
  },
  { name: 'My Profile', icon: FiUser, link: '/profile' },
];

export const DEFAULT_NAV_ITEMS: NavItem[] = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    icon: HiOutlineViewGrid,
    disabled: true,
  },

  {
    name: 'Profile',
    link: '/profile',
    icon: FiUser,
    isBottom: true,
    disabled: true,
  },
];
