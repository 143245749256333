import { ActionType } from '../action-types';
import { Dispatch } from 'redux';
import { UserAction } from '../actions/user';
import { HelperAction } from '../actions/helper';
import {
  CreateUser,
  LoginData,
  RequestWorkerData,
  UpdateUser,
  UpdateWorkerData,
  UploadAvatarData,
  User,
  VerifyPhoneData,
  Worker,
} from '@vyce/core/src/types';
import { createUserRequest, updateMeRequest, userMeRequest } from '@vyce/core/src/api/users';
import {
  loginRequest,
  redeemVerificationCodeRequest,
  sendVerificationSMSRequest,
} from '@vyce/core/src/api/auth';
import { store } from '../store';
import { updateNavItems } from './helper';
import { getFromLS, saveToLS } from '@vyce/core/src/utils/local-storage';
import { saveAvatarRequest } from '@vyce/core/src/api/storage';
import { WORKER_NAV_ITEMS } from '../../views/main/constants';
import { createWorkerRequest, updateWorkerRequest } from '@vyce/core/src/api/connect';
import { getQualificationsRequest } from '@vyce/core/src/api/checkers';
import { CAPTCHA_AVOIDER } from '@vyce/core/src/api/config';

const updateToken = (token: string, dispatch: Dispatch<HelperAction>) => {
  saveToLS('token', token);
  dispatch({
    type: ActionType.SET_ACCESS_TOKEN,
    payload: token,
  });
};

const handleMeRequest = async (token: string): Promise<User> => {
  const meResult = await userMeRequest(token);
  const userData: User = meResult.data || {};
  return { ...userData };
};

const clearOnboardingLSData = () => {
  saveToLS('goal', '');
  saveToLS('first_name', '');
  saveToLS('last_name', '');
};

export const setUserData = (data: Partial<User>) => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: ActionType.SET_USER_DATA,
      payload: data,
    });
  };
};

export const clearUser = () => {
  return (dispatch: Dispatch<UserAction | HelperAction>) => {
    saveToLS('token', '');
    dispatch({
      type: ActionType.CLEAR_HELPERS,
    });
    dispatch({
      type: ActionType.SET_INITIAL_USER_STATE,
    });
  };
};

export const userRegister = ({
  data,
  redirectUrl,
  saveToStore,
  recaptcha_token,
  handleServerError,
}: CreateUser) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: true,
      });
      const result = await createUserRequest(data, recaptcha_token);
      if (redirectUrl) {
        dispatch({
          type: ActionType.SET_REDIRECT_TO,
          payload: redirectUrl,
        });
      }

      if (saveToStore) {
        dispatch({
          type: ActionType.SET_USER_DATA,
          payload: result.data,
        });
      }

      const username = data.email || data.phone || '';
      const loginResult = await loginRequest({
        username,
        password: data.password_1,
        client_secret: CAPTCHA_AVOIDER,
      });
      const token = loginResult.data.access_token;

      updateToken(token, dispatch);

      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });

      if (data.phone) {
        sendVerificationSMSRequest({ recaptcha_token: CAPTCHA_AVOIDER });
      }

      clearOnboardingLSData();
    } catch (error) {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const verifyPhone = ({ code, redirectUrl, skip, handleServerError }: VerifyPhoneData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    let token = store.getState().helper.access_token;
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: true,
      });

      if (!skip) {
        await redeemVerificationCodeRequest(+code);
      }
      const userState = await handleMeRequest(token as string);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: userState,
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      if (redirectUrl) {
        dispatch({
          type: ActionType.SET_REDIRECT_TO,
          payload: redirectUrl,
        });
      }
    } catch (error) {
      clearUser();
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const me = () => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    //TODO remove token from redux
    let token = store.getState().helper.access_token;
    if (!token) {
      token = getFromLS('token');
      dispatch({
        type: ActionType.SET_ACCESS_TOKEN,
        payload: token,
      });
    }
    try {
      const userState = await handleMeRequest(token as string);

      if (userState.worker) {
        const res = await getQualificationsRequest({});
        userState.worker.qualifications = res.data;
      }
      updateNavItems(userState, dispatch);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: userState,
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      clearUser();
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
    }
  };
};

export const userLogin = ({ username, password, recaptcha_token, handleServerError }: LoginData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: true,
      });
      const result = await loginRequest({ username, password, client_secret: recaptcha_token });
      const token = result.data.access_token;

      updateToken(token, dispatch);

      const userState = await handleMeRequest(token as string);

      updateNavItems(userState, dispatch);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: userState,
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });

      dispatch({
        type: ActionType.SET_REDIRECT_TO,
        payload: '/dashboard',
      });
    } catch (error) {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const updateUser = ({
  data,
  isNotify,
  callback,
  showNotification,
  handleServerError,
}: UpdateUser) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    try {
      let token = store.getState().helper.access_token;
      let uuid = store.getState().user.uuid;
      dispatch({
        type: ActionType.SET_LOADING,
        payload: true,
      });

      const result = await updateMeRequest(token as string, uuid, data);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: result.data,
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      if (isNotify) {
        showNotification({
          message: 'Your data successfully updated',
          options: {
            variant: 'success',
          },
        });
      }
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const uploadUserAvatar = ({ file, saveToProfile, handleServerError }: UploadAvatarData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    dispatch({
      type: ActionType.SET_LOADING,
      payload: true,
    });
    const token = store.getState().helper.access_token;
    const uuid = store.getState().user.uuid;
    try {
      const result = await saveAvatarRequest(file, token as string, uuid);
      const photo = result.data;
      let userData = { photo };

      if (saveToProfile) {
        const result = await updateMeRequest(token as string, uuid, userData);
        userData = result.data;
      }

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: userData,
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const createWorker = (worker: RequestWorkerData, handleServerError: (e: any) => void) => {
  return async (dispatch: Dispatch<HelperAction | UserAction>) => {
    const token = store.getState().helper.access_token;
    try {
      const workerRes = await createWorkerRequest(token as string, worker);
      const workerData: Worker = workerRes.data;

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { worker: workerData },
      });
      dispatch({
        type: ActionType.SET_NAV_ITEMS,
        payload: WORKER_NAV_ITEMS,
      });
      dispatch({
        type: ActionType.SET_REDIRECT_TO,
        payload: '/profile',
      });
      dispatch({
        type: ActionType.SET_SHOW_TOUR,
        payload: true,
      });
    } catch (error) {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const updateWorker = ({ showNotification, data, handleServerError }: UpdateWorkerData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    const token = store.getState().helper.access_token;
    const uuid = store.getState().user.worker?.uuid;
    const qualifications = store.getState().user.worker?.qualifications || null;
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: true,
      });

      const result = await updateWorkerRequest(token as string, uuid as string, data);
      const workerData: Worker = { ...result.data, qualifications };

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { worker: workerData },
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      showNotification({
        message: 'Your data successfully updated',
        options: {
          variant: 'success',
        },
      });
    } catch (error) {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};
