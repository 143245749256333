import React from 'react';
import { AvailabilityToWork } from './AvailabilityToWork';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

export const AvailabilityToWorkForm: React.FC = () => {
  return (
    <div>
      <OnboardingStepTitle text="When can you start?" />

      <AvailabilityToWork />
    </div>
  );
};
