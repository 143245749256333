import React, { useContext, useEffect, useState } from 'react';

import { Avatar, Box, CssBaseline, LinearProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { Sidebar } from '@vyce/core/src/components/Sidebar';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { getFromLS } from '@vyce/core/src/utils/local-storage';
import { ColorThemeProvider } from '@vyce/core/src/contexts/colorThemeContext';
import { Header } from '@vyce/core/src/components/Header';
import { MainNotifications } from '@vyce/core/src/components/MainNotifications';
import { ColorThemeType, ThemeType } from '@vyce/core/src/types';

import createRoutes from '../../router';
import useStyles from './styles';
import { useActions, useTypedSelector } from '../../hooks';
import { ConnectAppTour } from './components/ConnectAppTour';

const routes = createRoutes();

interface Props {
  changeColorTheme: (type: ThemeType) => void;
  themeType?: ThemeType;
  colorTheme: ColorThemeType;
}

export const Main: React.FC<Props> = ({ changeColorTheme, colorTheme, themeType }) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const { user } = useTypedSelector(state => state);
  const [open, setOpen] = useState<boolean>(!window.location.href.includes('onboarding'));
  const [loading, setLoading] = useState<boolean>(true);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const history = useHistory();
  const { isDesktop } = useContext(DeviceContext);
  const { clearRedirectTo, me, setShowTour, clearUser, verifyPhone } = useActions();
  const { redirectTo, navItems } = useTypedSelector(state => state.helper);
  const { enqueueSnackbar } = useSnackbar();
  const token = getFromLS('token');

  const handleDrawerToggle = () => {
    if (isDesktop) {
      return setOpen(!open);
    }
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    async function fetchUserData() {
      await me();
      setLoading(false);
    }

    if (token) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, []);

  // listens and redirects from state
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      clearRedirectTo();
    }
  }, [enqueueSnackbar, clearRedirectTo, redirectTo, history]);

  const logout = () => {
    clearUser();
    setOpen(false);
    // make sure localStorage.clear function will be last
    setTimeout(() => localStorage.clear());
  };

  return (
    <ColorThemeProvider value={{ colorTheme }}>
      <div className={classes.root}>
        <CssBaseline />
        {user.uuid && (
          <Header
            themeType={themeType}
            loading={loading}
            handleDrawerToggle={handleDrawerToggle}
            changeColorTheme={changeColorTheme}
            open={open}
            user={user}
            setShowTour={setShowTour}>
            <Box
              style={{ cursor: 'pointer' }}
              display="flex"
              alignItems="center"
              onClick={() => history.push('/profile')}>
              <Avatar data-tour="profile" src={user.photo?.url} className={classes.avatar} />
              {!isMobile && (
                <Typography className={classes.name}>{`${user.first_name} ${user.last_name}`}</Typography>
              )}
            </Box>
          </Header>
        )}
        {!loading && user.uuid && (
          <>
            <Sidebar
              handleDrawerToggle={handleDrawerToggle}
              open={open}
              logout={logout}
              mobileOpen={mobileOpen}
              setMobileOpen={setMobileOpen}
              navItems={navItems}
            />
          </>
        )}
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
            [classes.authLayout]: !user.uuid,
          })}>
          {loading ? <LinearProgress className={classes.progress} /> : <>{routes}</>}
        </main>

        <MainNotifications userId={user.uuid} verifyPhone={verifyPhone} />

        <ConnectAppTour />
      </div>
    </ColorThemeProvider>
  );
};
