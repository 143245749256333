import React, { useContext, useEffect, useState } from 'react';
import { UserPersonalInfo } from '@vyce/core/src/views/profile/user/UserPersonalInfo';
import { WORKER_PROFILE_TABS } from '@vyce/core/src/views/profile/config';
import { UserSurvey, Worker } from '@vyce/core/src/types';
import {
  createCustomQualificationRequest,
  deleteCustomQualificationImageRequest,
  deleteCustomQualificationRequest,
  getCheckersRequest,
  getQualificationsRequest,
  getRtwRequest,
  triggerCheckerRequest,
  updateCustomQualificationRequest,
  uploadCustomQualificationImageRequest,
} from '@vyce/core/src/api/checkers';
import { getTagListRequest } from '@vyce/core/src/api/handbook';
import {
  getPassportRequest,
  uploadPassportRequest,
  uploadSelfieRequest,
} from '@vyce/core/src/api/documents';
import { downloadHealthAndSafetyPDFRequest } from '@vyce/core/src/api/pay';
import { getSurveyAnswers, getSurveysRequest, saveSurveyAnswers } from '@vyce/core/src/api/survey';
import { changePasswordRequest } from '@vyce/core/src/api/auth';
import { HealthAndSafety, SEARCH_HEALTH_SURVEY_SUBSTRING } from '@vyce/core/src/views/profile/user/H&S';
import { WorkerProfessionalInfo } from '@vyce/core/src/views/profile/user/WorkerProfessionalInfo';
import { Qualifications } from '@vyce/core/src/modules';
import { Documents } from '@vyce/core/src/views/profile/user/Documents';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useActions, useTypedSelector } from '../../hooks';

export const RouteWorkerProfileComponent: React.FC = () => {
  const { updateWorker, uploadUserAvatar, updateUser, verifyPhone, me } = useActions();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { user, helper } = useTypedSelector(state => state);
  const tab = WORKER_PROFILE_TABS.find(tab => window.location.href.includes(tab.link));
  const [survey, setSurvey] = useState<UserSurvey>();
  const { access_token, userDataLoading } = helper;

  const getSurveys = async () => {
    try {
      const surveysRes = await getSurveysRequest(SEARCH_HEALTH_SURVEY_SUBSTRING);
      const surveyRes = surveysRes.data?.items[0];
      if (!surveyRes) {
        return showNotification({
          message: 'Health and Safety Questionnaire not found',
          options: { variant: 'error' },
        });
      }
      const res = await getSurveyAnswers(surveyRes?.uuid);
      if (res.data) {
        setSurvey(res.data);
      }
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  switch (tab?.label) {
    case 'Personal':
      return (
        <UserPersonalInfo
          changePasswordRequest={changePasswordRequest}
          updateUser={updateUser}
          uploadUserAvatar={uploadUserAvatar}
          verifyPhone={verifyPhone}
          token={access_token}
          userDataLoading={!!userDataLoading}
          user={user}
        />
      );
    case 'Professional':
      return (
        <WorkerProfessionalInfo
          jobTitle={user.employee?.job_title as string}
          userCity={user.address?.address_line_3}
          userDataLoading={userDataLoading}
          updateWorker={updateWorker}
          helper={helper}
          worker={user.worker as Worker}
        />
      );
    case 'ID & RTW':
      return (
        <Documents
          getUser={me as any}
          getRtwRequest={getRtwRequest}
          uploadSelfieRequest={uploadSelfieRequest}
          uploadPassportRequest={uploadPassportRequest}
          triggerCheckerRequest={triggerCheckerRequest}
          getPassportRequest={getPassportRequest}
          user={user}
        />
      );
    case 'Qualifications':
      return (
        <Qualifications
          getQualificationsRequest={getQualificationsRequest}
          getCheckersRequest={getCheckersRequest}
          triggerCheckerRequest={triggerCheckerRequest}
          createCustomQualificationRequest={createCustomQualificationRequest}
          updateCustomQualificationRequest={updateCustomQualificationRequest}
          deleteCustomQualificationRequest={deleteCustomQualificationRequest}
          uploadCustomQualificationImageRequest={uploadCustomQualificationImageRequest}
          deleteCustomQualificationImageRequest={deleteCustomQualificationImageRequest}
          getTagListRequest={getTagListRequest}
        />
      );
    case 'Medical':
      return (
        <>
          {!!survey && (
            <HealthAndSafety
              downloadHealthAndSafetyPDFRequest={downloadHealthAndSafetyPDFRequest}
              setSurvey={setSurvey}
              survey={survey}
              saveSurveyAnswers={saveSurveyAnswers}
            />
          )}
        </>
      );
    default:
      return <></>;
  }
};
