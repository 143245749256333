import React from 'react';

import { Box, Drawer, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { TileList } from '@vyce/core/src/views/hiring/components/TileList';
import { FilterSystem, EmptyList, CommonNavBarBlock } from '@vyce/core/src/components';
import { JobPage } from '@vyce/core/src/views/hiring/JobPage';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { useStyles } from '@vyce/core/src/views/hiring/styles';
import { MapView } from '@vyce/core/src/views/map/MapView';
import { WORKER_JOBS_TABS } from '@vyce/core/src/views/profile/config';
import {
  DeclineApplicationDialog,
  CancelDeclinationDialog,
  AcceptApplicationDialog,
  Sorting,
  MapButton,
} from '@vyce/core/src/views/hiring/components';

import {
  ApplyJobDialog,
  ConnectButtons,
  SuccessConnectionDialog,
  CancelApplicationDialog,
  WorkerJobTile,
} from './components';
import { useJobsData } from './hooks';
import config from '@vyce/core/src/assets/config';

export const Jobs = () => {
  const classes = useStyles();

  const {
    isMobile,
    openJobDetails,
    mapMode,
    jobsCount,
    filterSections,
    debouncedHandleFilter,
    markers,
    selectedMarker,
    paginationOptions,
    loading,
    jobs,
    selectedJob,
    user,
    connectedJob,
    successConnectionDialogOpen,
    acceptDialogOpen,
    cancelDeclinationDialogOpen,
    declineApplicationDialogOpen,
    cancelApplicationDialogOpen,
    openApplyJobDialog,
    history,
    applyJob,
    setOpenApplyJobDialog,
    setCancelApplicationDialogOpen,
    cancelApplication,
    setDeclineApplicationDialogOpen,
    declineApplication,
    setCancelDeclinationDialogOpen,
    cancelDeclination,
    setAcceptDialogOpen,
    acceptApplication,
    setConnectedJob,
    setSuccessConnectionDialogOpen,
    back,
    openCancelDeclinationDialog,
    openAcceptDialog,
    openApplyDialog,
    openCancelApplicationDialog,
    openDeclineConnectionDialog,
    goToJob,
    getMyPosition,
    sort,
    setMapMode,
    handleSearchChange,
  } = useJobsData();

  return (
    <Box padding={isMobile ? 1 : 0} height="calc(100% - 64px)">
      <CommonNavBarBlock tabItems={WORKER_JOBS_TABS} />

      <Box
        display="flex"
        flexDirection="column"
        className={clsx(classes.content, {
          [classes.contentShift]: openJobDetails,
        })}>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          marginTop={3}
          marginBottom={3}
          alignItems="center">
          <Typography color="textSecondary" variant="subtitle2">
            Found {`${jobsCount} result${jobsCount > 1 ? 's' : ''}`}
          </Typography>

          <Box display="flex" gridGap={16}>
            <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />

            <MapButton mapMode={mapMode} onMapModeChange={() => setMapMode(mode => !mode)} />

            <Sorting
              sort={sort}
              sortOptions={['Recent', 'Nearest', 'Highest salary', 'Starts soon', 'Starts late']}
            />

            <FilterSystem filtersSections={filterSections} onFiltersChange={debouncedHandleFilter} />
          </Box>
        </Box>

        <Box className={classes.flexContentWrapper} pb={3}>
          {mapMode ? (
            <MapView
              height="calc(100vh - 180px)"
              markers={markers}
              selectedMarker={selectedMarker}
              onMarkerClick={goToJob}
              myPosition={getMyPosition()}
            />
          ) : (
            <TileList
              paginationOptions={paginationOptions}
              loading={loading}
              emptyComponent={
                <EmptyList
                  image={config.icons3D.lightning}
                  title="Hmmm! No jobs match your profile right now."
                  subtitle="We’ll let you know as soon as this changes. In the mean time why not build your profile to make it stand out..."
                  buttonText="Update My Profile"
                  callback={() => history.push('/profile/professional')}
                />
              }>
              <>
                {jobs.map((job, index) => (
                  <Grid key={index} item xs={12} md={openJobDetails ? 12 : 6} lg={openJobDetails ? 6 : 4}>
                    <WorkerJobTile currentJob={job} goToJob={goToJob} workerTags={user.worker?.tags}>
                      <ConnectButtons
                        openCancelDeclinationDialog={openCancelDeclinationDialog}
                        openAcceptDialog={openAcceptDialog}
                        openApplyDialog={openApplyDialog}
                        openCancelApplicationDialog={openCancelApplicationDialog}
                        openDeclineConnectionDialog={openDeclineConnectionDialog}
                        job={job}
                      />
                    </WorkerJobTile>
                  </Grid>
                ))}
              </>
            </TileList>
          )}
        </Box>
      </Box>

      {selectedJob && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={openJobDetails}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <JobPage job={selectedJob} back={back} workerTags={user.worker?.tags}>
            <ConnectButtons
              openCancelDeclinationDialog={openCancelDeclinationDialog}
              openAcceptDialog={openAcceptDialog}
              openApplyDialog={openApplyDialog}
              openCancelApplicationDialog={openCancelApplicationDialog}
              openDeclineConnectionDialog={openDeclineConnectionDialog}
              isJobPage={true}
              job={selectedJob}
            />
          </JobPage>
        </Drawer>
      )}

      <ApplyJobDialog
        applyJob={applyJob}
        open={openApplyJobDialog}
        setOpenApplyJobDialog={setOpenApplyJobDialog}
      />

      <CancelApplicationDialog
        open={cancelApplicationDialogOpen}
        loading={loading}
        setOpen={setCancelApplicationDialogOpen}
        cancelApplication={cancelApplication}
      />

      <DeclineApplicationDialog
        open={declineApplicationDialogOpen}
        loading={loading}
        setOpen={setDeclineApplicationDialogOpen}
        declineApplication={declineApplication}
      />

      <CancelDeclinationDialog
        open={cancelDeclinationDialogOpen}
        loading={loading}
        setOpen={setCancelDeclinationDialogOpen}
        cancelDeclination={cancelDeclination}
      />

      <AcceptApplicationDialog
        open={acceptDialogOpen}
        loading={loading}
        setOpen={setAcceptDialogOpen}
        accept={acceptApplication}
      />

      {connectedJob && (
        <SuccessConnectionDialog
          open={successConnectionDialogOpen}
          handleClose={() => {
            setSuccessConnectionDialogOpen(false);
            setConnectedJob(undefined);
          }}
          contactEmail={connectedJob.contact_email}
          contactName={connectedJob.contact_name}
          contactPhone={connectedJob.contact_phone}
        />
      )}
    </Box>
  );
};
