import React, { useContext, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Button, FormControlLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/date-fns';

import { CompaniesField, JobsField } from '@vyce/core/src/components/controlled-inputs';
import { Position } from '@vyce/core/src/types';
import { formatDate } from '@vyce/core/src/utils/dates';
import { AppCheckbox, AppKeyboardDatePicker } from '@vyce/core/src/components/inputs';

import { useTypedSelector } from '../../../hooks';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

interface Props {
  save: Function;
}

export const PositionForm: React.FC<Props> = ({ save }) => {
  const methods = useForm<Position>({
    defaultValues: {
      title: '',
      company: undefined,
      start_date: formatDate(new Date()),
      end_date: null,
      currently_active: true,
    },
  });
  const [isNowadays, setIsNowadays] = useState<boolean>(true);
  const { access_token } = useTypedSelector(state => state.helper);
  const { showNotification } = useContext(NotificationContext);
  const { control, reset } = methods;

  const handleSubmit = (data: any) => {
    const updatedPosition: Position = {
      ...data,
      title: data.title?.name,
    };
    if (dayjs(data.start_date) > dayjs(data.end_date)) {
      return showNotification({ message: 'Chose correct dates', options: { variant: 'error' } });
    }
    save(updatedPosition);
    setIsNowadays(true);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <div>
        <CompaniesField
          margin="normal"
          name="company"
          label="Company I have worked for before"
          multiple={false}
          rules={{ required: 'Company is required' }}
        />

        <JobsField
          margin="normal"
          name="title"
          multiple={false}
          label="Role I had"
          rules={{ required: 'Role is required' }}
          token={access_token}
        />

        <Box display="flex" width="100%" marginTop={1}>
          <Controller
            name="currently_active"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <AppCheckbox
                    onChange={e => {
                      const checked = e.target.checked;
                      field.onChange(checked);
                      setIsNowadays(checked);
                    }}
                    color="primary"
                    checked={field.value}
                  />
                }
                label="Still working"
              />
            )}
          />
        </Box>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            name="start_date"
            control={control}
            render={props => (
              <AppKeyboardDatePicker
                value={props.field.value}
                onChange={data => props.field.onChange(formatDate(data))}
                label="Start Date"
                margin="normal"
                fullWidth
                disableFuture
                views={['year', 'month']}
                id="date-picker-dialog"
              />
            )}
          />
        </MuiPickersUtilsProvider>

        {!isNowadays && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              name="end_date"
              control={control}
              render={props => (
                <AppKeyboardDatePicker
                  value={props.field.value}
                  onChange={data => props.field.onChange(formatDate(data))}
                  label="End Date"
                  margin="normal"
                  fullWidth
                  disableFuture
                  views={['year', 'month']}
                  id="date-picker-dialog"
                />
              )}
            />
          </MuiPickersUtilsProvider>
        )}

        <Box display="flex" justifyContent="flex-end">
          <Button onClick={methods.handleSubmit(handleSubmit)} color="primary" variant="outlined">
            Add Work Experience
          </Button>
        </Box>
      </div>
    </FormProvider>
  );
};
