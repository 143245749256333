import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { CopyToClipboard } from '@vyce/core/src/components/CopyToClipboard';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

interface Props {
  open: boolean;
  handleClose: Function;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

export const SuccessConnectionDialog: React.FC<Props> = ({
  open,
  handleClose,
  contactName,
  contactPhone,
  contactEmail,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme();

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Awesome! You’ve connected.</DialogTitle>

      <DialogContent>
        <DialogContentText>Contact them now so you don't miss out on the job.</DialogContentText>

        <Box display="flex" alignItems="center">
          <Typography color="primary" align="center" variant="subtitle1">
            Contact Name: {contactName}
          </Typography>
          <CopyToClipboard text={contactName} color={theme.palette.primary.main} />
        </Box>

        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography color="primary" align="center" variant="subtitle1">
            E-mail them: {contactEmail}
          </Typography>
          <CopyToClipboard text={contactName} color={theme.palette.primary.main} />
        </Box>

        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography color="primary" align="center" variant="subtitle1">
            Call them: {contactPhone}
          </Typography>
          <CopyToClipboard text={contactName} color={theme.palette.primary.main} />
        </Box>

        <Box display="flex" alignItems="center" marginTop={2}>
          <Typography align="center" variant="subtitle1">
            Good luck!
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          size="large"
          style={{ width: 150 }}
          color="primary"
          onClick={() => handleClose()}
          variant="contained">
          Ok, close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
