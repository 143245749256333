import React from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { AppRadioButton } from '@vyce/core/src/components/inputs/AppRadio';
import { SEARCH_STATUSES } from '@vyce/core/src/constants';

export const AvailabilityToWork: React.FC = () => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <FormControl {...field} component="fieldset">
          <RadioGroup aria-label="gender" value={field.value || null} onChange={field.onChange}>
            <Box display="flex" m={1} flexWrap="wrap">
              <FormControlLabel
                value={SEARCH_STATUSES[0].name}
                control={<AppRadioButton color="primary" name={SEARCH_STATUSES[0].name} />}
                label=""
              />
              <FormControlLabel
                value={SEARCH_STATUSES[1].name}
                control={<AppRadioButton color="primary" name={SEARCH_STATUSES[1].name} />}
                label=""
              />
              <FormControlLabel
                value={SEARCH_STATUSES[2].name}
                control={<AppRadioButton color="primary" name={SEARCH_STATUSES[2].name} />}
                label=""
              />
            </Box>
          </RadioGroup>
        </FormControl>
      )}
      name="search_status"
      control={control}
    />
  );
};
