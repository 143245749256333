import React, { useEffect } from 'react';
import { Login } from '@vyce/core/src/views/auth/components/Login';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';

const WELCOME_MESSAGE = 'Welcome to Vyce Connect';
const SUBTITLE = 'connect to jobs, people & companies';

export const WorkerLogin: React.FC = (props: any) => {
  const { clearHelpers, userLogin } = useActions();
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const redirectUrl = props?.location?.state?.from || '/dashboard';

  useEffect(() => {
    clearHelpers();
  }, []);

  return (
    <Login
      redirectUrl={redirectUrl}
      userLogin={userLogin}
      loading={userDataLoading}
      title={WELCOME_MESSAGE}
      subtitle={SUBTITLE}
    />
  );
};
